export const SUPPORTED_LANG = ['en', 'zh', 'hi', 'es', 'fr', 'bn', 'pt', 'ru', 'id', 'de', 'ja', 'ko', 'vi', 'it', 'tr', 'ta', 'sw', 'tl'];

export let LANG = localStorage.getItem('lang');

export const setLang = (lang: string) => {
	LANG = lang;
	localStorage.setItem('lang', lang);
	return lang;
}

const initLang = () => {
	console.log(SUPPORTED_LANG)
	return setLang(navigator.languages.map(lang => lang.split('-')[0])
		.find(lang => SUPPORTED_LANG.includes(lang)) ?? SUPPORTED_LANG[0]);
}

if (LANG == null) initLang();

const appLocalizations: { [key in string]: { [key: string]: string } } = {
	en: {
		homepage_headline: 'Capture, Share, Decide: Your Style, Their Say',
		homepage_step1: 'Take a photo of what you want an opinion on',
		homepage_step2: 'Share your thoughts and receive votes in return',
		homepage_step3: 'See results after a few minutes',
		privacy_policy: 'Privacy Policy',
		terms_of_use: 'Terms of Use',
		contact_us: 'Contact Us',
		email_placeholder: 'Email',
		message_placeholder: 'Type your message here...',
		submit: 'Submit',
		send_success: 'Message sent',
		send_failure: 'Failed to send message',
	},
	fr: {
		homepage_headline: 'Capture, Partage, Décide: Votre style, leur avis',
		homepage_step1: 'Prends une photo de ce sur quoi tu veux un avis',
		homepage_step2: 'Partage ton avis et reçois des votes en retour',
		homepage_step3: 'Consulte les résultats après quelques minutes',
		privacy_policy: 'Politique de confidentialité',
		terms_of_use: "Conditions d'utilisation",
		contact_us: 'Contact',
		email_placeholder: 'E-mail',
		message_placeholder: 'Tape ton message ici...',
		submit: 'Envoyer',
		send_success: 'Message envoyé',
		send_failure: "Erreur lors de l'envoi",
	},
	zh: {
		homepage_headline: '拍照，分享，决策：你的风格，他们的意见',
		homepage_step1: '拍下你想要获得意见的照片',
		homepage_step2: '分享你的想法并收到投票',
		homepage_step3: '几分钟后查看结果',
		privacy_policy: '隐私政策',
		terms_of_use: '使用条款',
		contact_us: '联系我们',
		email_placeholder: '电子邮件',
		message_placeholder: '在此输入你的消息...',
		submit: '提交',
		send_success: '消息已发送',
		send_failure: '消息发送失败',
	},
	hi: {
		homepage_headline: 'तस्वीर लें, शेयर करें, निर्णय लें: आपकी शैली, उनका कहना',
		homepage_step1: 'जिस चीज़ पर आप राय चाहते हैं, उसकी फोटो लें',
		homepage_step2: 'अपनी राय साझा करें और वोट प्राप्त करें',
		homepage_step3: 'कुछ मिनटों में परिणाम देखें',
		privacy_policy: 'गोपनीयता नीति',
		terms_of_use: 'उपयोग की शर्तें',
		contact_us: 'संपर्क करें',
		email_placeholder: 'ईमेल',
		message_placeholder: 'यहां अपना संदेश टाइप करें...',
		submit: 'सबमिट करें',
		send_success: 'संदेश भेजा गया',
		send_failure: 'संदेश भेजने में विफल',
	},
	es: {
		homepage_headline: 'Captura, Comparte, Decide: Tu estilo, Su opinión',
		homepage_step1: 'Toma una foto de lo que quieres una opinión',
		homepage_step2: 'Comparte tus pensamientos y recibe votos a cambio',
		homepage_step3: 'Consulta los resultados después de unos minutos',
		privacy_policy: 'Política de privacidad',
		terms_of_use: 'Términos de uso',
		contact_us: 'Contáctanos',
		email_placeholder: 'Correo electrónico',
		message_placeholder: 'Escribe tu mensaje aquí...',
		submit: 'Enviar',
		send_success: 'Mensaje enviado',
		send_failure: 'Error al enviar mensaje',
	},
	bn: {
		homepage_headline: 'ছবি তোলা, শেয়ার করুন, সিদ্ধান্ত নিন: আপনার শৈলী, তাদের মতামত',
		homepage_step1: 'যে বিষয়ে আপনি মতামত চান তা ফটো তুলুন',
		homepage_step2: 'আপনার চিন্তা শেয়ার করুন এবং প্রতিদানে ভোট পান',
		homepage_step3: 'কিছু মিনিটের মধ্যে ফলাফল দেখুন',
		privacy_policy: 'গোপনীয়তা নীতি',
		terms_of_use: 'ব্যবহারের শর্তাবলী',
		contact_us: 'আমাদের সাথে যোগাযোগ করুন',
		email_placeholder: 'ইমেইল',
		message_placeholder: 'এখানে আপনার বার্তা লিখুন...',
		submit: 'জমা দিন',
		send_success: 'বার্তা পাঠানো হয়েছে',
		send_failure: 'বার্তা পাঠাতে ব্যর্থ',
	},
	pt: {
		homepage_headline: 'Captura, Compartilhe, Decida: Seu Estilo, a Opinião Deles',
		homepage_step1: 'Tire uma foto do que você quer uma opinião',
		homepage_step2: 'Compartilhe seus pensamentos e receba votos em troca',
		homepage_step3: 'Veja os resultados após alguns minutos',
		privacy_policy: 'Política de Privacidade',
		terms_of_use: 'Termos de Uso',
		contact_us: 'Contate-nos',
		email_placeholder: 'Email',
		message_placeholder: 'Digite sua mensagem aqui...',
		submit: 'Enviar',
		send_success: 'Mensagem enviada',
		send_failure: 'Falha ao enviar mensagem',
	},
	ru: {
		homepage_headline: 'Сделай фото, делись, решай: твой стиль, их мнение',
		homepage_step1: 'Сделай фото того, на что ты хочешь получить мнение',
		homepage_step2: 'Поделись своим мнением и получи голоса в ответ',
		homepage_step3: 'Посмотри результаты через несколько минут',
		privacy_policy: 'Политика конфиденциальности',
		terms_of_use: 'Условия использования',
		contact_us: 'Связаться с нами',
		email_placeholder: 'Электронная почта',
		message_placeholder: 'Введите ваше сообщение здесь...',
		submit: 'Отправить',
		send_success: 'Сообщение отправлено',
		send_failure: 'Не удалось отправить сообщение',
	},
	ja: {
		homepage_headline: '写真を撮る、シェア、決定：あなたのスタイル、彼らの意見',
		homepage_step1: '意見を求めたいものの写真を撮る',
		homepage_step2: '考えを共有し、投票を受け取る',
		homepage_step3: '数分後に結果を確認',
		privacy_policy: 'プライバシーポリシー',
		terms_of_use: '利用規約',
		contact_us: 'お問い合わせ',
		email_placeholder: 'メールアドレス',
		message_placeholder: 'ここにメッセージを入力...',
		submit: '送信',
		send_success: 'メッセージが送信されました',
		send_failure: 'メッセージの送信に失敗しました',
	},
	ko: {
		homepage_headline: '사진 찍기, 공유, 결정: 당신의 스타일, 그들의 의견',
		homepage_step1: '의견을 원하는 것의 사진을 찍으세요',
		homepage_step2: '당신의 생각을 공유하고 투표를 받으세요',
		homepage_step3: '몇 분 후에 결과를 확인하세요',
		privacy_policy: '개인정보 보호정책',
		terms_of_use: '이용 약관',
		contact_us: '연락처',
		email_placeholder: '이메일',
		message_placeholder: '여기에 메시지를 입력하세요...',
		submit: '제출',
		send_success: '메시지가 전송되었습니다',
		send_failure: '메시지 전송 실패',
	},
	vi: {
		homepage_headline: 'Chụp, Chia sẻ, Quyết định: Phong cách của bạn, Ý kiến của họ',
		homepage_step1: 'Chụp ảnh những gì bạn muốn nhận ý kiến',
		homepage_step2: 'Chia sẻ suy nghĩ của bạn và nhận phiếu bầu',
		homepage_step3: 'Xem kết quả sau vài phút',
		privacy_policy: 'Chính sách bảo mật',
		terms_of_use: 'Điều khoản sử dụng',
		contact_us: 'Liên hệ với chúng tôi',
		email_placeholder: 'Email',
		message_placeholder: 'Nhập tin nhắn của bạn ở đây...',
		submit: 'Gửi',
		send_success: 'Tin nhắn đã được gửi',
		send_failure: 'Gửi tin nhắn không thành công',
	},
	it: {
		homepage_headline: 'Scatta, Condividi, Decidi: Il tuo stile, il loro parere',
		homepage_step1: 'Fai una foto di ciò su cui vuoi un parere',
		homepage_step2: 'Condividi i tuoi pensieri e ricevi voti in cambio',
		homepage_step3: 'Vedi i risultati dopo qualche minuto',
		privacy_policy: 'Politica sulla privacy',
		terms_of_use: 'Termini di utilizzo',
		contact_us: 'Contattaci',
		email_placeholder: 'Email',
		message_placeholder: 'Scrivi il tuo messaggio qui...',
		submit: 'Invia',
		send_success: 'Messaggio inviato',
		send_failure: 'Invio del messaggio fallito',
	},
	tr: {
		homepage_headline: 'Çek, Paylaş, Karar Ver: Senin Tarzın, Onların Yorumları',
		homepage_step1: 'Fikir almak istediğin şeyi fotoğraflayın',
		homepage_step2: 'Düşüncelerini paylaş ve karşılığında oy al',
		homepage_step3: 'Birkaç dakika sonra sonuçları gör',
		privacy_policy: 'Gizlilik Politikası',
		terms_of_use: 'Kullanım Şartları',
		contact_us: 'Bize Ulaşın',
		email_placeholder: 'E-posta',
		message_placeholder: 'Mesajınızı buraya yazın...',
		submit: 'Gönder',
		send_success: 'Mesaj gönderildi',
		send_failure: 'Mesaj gönderilemedi',
	},
	sw: {
		homepage_headline: 'Piga picha, Shiriki, Amua: Mtindo wako, Maoni yao',
		homepage_step1: 'Piga picha ya kile unachotaka kupata maoni',
		homepage_step2: 'Shiriki mawazo yako na pokea kura kwa muktadha',
		homepage_step3: 'Angalia matokeo baada ya dakika chache',
		privacy_policy: 'Sera ya faragha',
		terms_of_use: 'Masharti ya matumizi',
		contact_us: 'Wasiliana nasi',
		email_placeholder: 'Barua pepe',
		message_placeholder: 'Andika ujumbe wako hapa...',
		submit: 'Tuma',
		send_success: 'Ujumbe umetumwa',
		send_failure: 'Imeshindwa kutuma ujumbe',
	},
	tl: {
		homepage_headline: 'Kumuha ng larawan, Ibahagi, Magpasya: Ang Iyong Estilo, Kanilang Opinyon',
		homepage_step1: 'Kumuha ng larawan ng iyong nais na kunin ng opinyon',
		homepage_step2: 'Ibahagi ang iyong mga saloobin at tumanggap ng boto kapalit',
		homepage_step3: 'Tingnan ang mga resulta pagkatapos ng ilang minuto',
		privacy_policy: 'Patakaran sa Privacy',
		terms_of_use: 'Mga Termino ng Paggamit',
		contact_us: 'Makipag-ugnayan sa Amin',
		email_placeholder: 'Email',
		message_placeholder: 'I-type ang iyong mensahe dito...',
		submit: 'I-submit',
		send_success: 'Ang mensahe ay naipadala',
		send_failure: 'Nabigong magpadala ng mensahe',
	},
	de: {
		homepage_headline: 'Fotografieren, Teilen, Entscheiden: Dein Stil, ihre Meinung',
		homepage_step1: 'Mach ein Foto von dem, zu dem du eine Meinung haben möchtest',
		homepage_step2: 'Teile deine Gedanken und erhalte Stimmen im Gegenzug',
		homepage_step3: 'Sieh dir die Ergebnisse nach ein paar Minuten an',
		privacy_policy: 'Datenschutzrichtlinie',
		terms_of_use: 'Nutzungsbedingungen',
		contact_us: 'Kontaktieren Sie uns',
		email_placeholder: 'E-Mail',
		message_placeholder: 'Gib deine Nachricht hier ein...',
		submit: 'Absenden',
		send_success: 'Nachricht gesendet',
		send_failure: 'Fehler beim Senden der Nachricht',
	},
	id: {
		homepage_headline: 'Ambil Foto, Bagikan, Putuskan: Gaya Anda, Pendapat Mereka',
		homepage_step1: 'Ambil foto dari apa yang ingin Anda dapatkan pendapatnya',
		homepage_step2: 'Bagikan pemikiran Anda dan terima suara sebagai balasan',
		homepage_step3: 'Lihat hasilnya setelah beberapa menit',
		privacy_policy: 'Kebijakan Privasi',
		terms_of_use: 'Ketentuan Penggunaan',
		contact_us: 'Hubungi Kami',
		email_placeholder: 'Email',
		message_placeholder: 'Ketik pesan Anda di sini...',
		submit: 'Kirim',
		send_success: 'Pesan terkirim',
		send_failure: 'Gagal mengirim pesan',
	},
	ta: {
		homepage_headline: 'படம் எடு, பகிரவும், முடிவு செய்யுங்கள்: உங்கள் முறை, அவர்களின் கருத்து',
		homepage_step1: 'நீங்கள் கருத்து பெற விரும்பும் பொருளின் புகைப்படத்தை எடுத்துக் கொள்ளுங்கள்',
		homepage_step2: 'உங்கள் எண்ணங்களை பகிர்ந்து, வோட்டுகளை பெறுங்கள்',
		homepage_step3: 'சில நிமிடங்களில் முடிவுகளை பார்க்கவும்',
		privacy_policy: 'பெரிசிய அறிவிப்பு',
		terms_of_use: 'பயன்பாடு விதிமுறைகள்',
		contact_us: 'நமக்கு தொடர்பு கொள்ளவும்',
		email_placeholder: 'இமெயில்',
		message_placeholder: 'இங்கே உங்கள் செய்தியைக் கையேற்றவும்...',
		submit: 'சமர்ப்பிக்கவும்',
		send_success: 'செய்தி அனுப்பப்பட்டது',
		send_failure: 'செய்தி அனுப்ப முடியவில்லை',
	},
}

export const getTranslation = (key: string) => appLocalizations[LANG ?? 'en'][key] ?? '';
